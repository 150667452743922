<template>
  <Sect id="ProjectSpecifications">
    <Wrap class="md">
      <div class="toggleContainer">
        <div class="toggle">
          <span
            v-for="option in toggleOptions"
            :key="option.id"
            :class="{ active: toggledId === option.id }"
            role="button"
            @click="toggledId = option.id">
            {{ option.label }}
          </span>
        </div>
      </div>
      <SelfProjectProgress v-if="toggledId === 1" :projectId="project.id" />
      <ProjectTechnical v-if="toggledId === 2" :project="project" />
      <div v-if="toggledId === 3" class="placementContainer">
        <Pic role="button" :src="project.placementImageUrl" @click.native="isModalOpen = true" />
      </div>
      <ModalCont v-if="isModalOpen" class="placementModal" @close="close">
        <Pic :src="project.placementImageUrl" />
      </ModalCont>
    </Wrap>
  </Sect>
</template>

<script>
import ProjectTechnical from './ProjectTechnical'
import SelfProjectProgress from 'views/SelfProjectProgress'

export default {
  components: {
    ProjectTechnical,
    SelfProjectProgress,
  },
  props: {
    project: Object,
  },
  data() {
    return {
      isModalOpen: false,
      toggledId: 2,
      toggleOptions: [
        {
          id: 1,
          label: 'PLANUOJAMA PROJEKTO EIGA',
        },
        {
          id: 2,
          label: 'TECHNINĖ INFORMACIJA',
        },
        {
          id: 3,
          label: 'MODULIŲ IŠDĖSTYMAS',
        },
      ],
    }
  },
  methods: {
    close() {
      this.isModalOpen = false
    },
  },
}
</script>

<style lang="scss">
#ProjectSpecifications {
  background: $white;
  padding-top: 6rem;
  position: relative;

  .toggleContainer {
    display: flex;
    justify-content: center;
  }

  .toggle {
    display: flex;
    flex-direction: column;
    font-size: $h3;
    font-weight: $bold;
    padding: 0.5rem 1rem;
    text-align: center;
    width: 45rem;
    @include lg {
      border: 2px solid $black;
      border-radius: $radius-round;
      flex-direction: row;
      font-size: unset;
      justify-content: space-around;
    }

    > span {
      border-radius: $radius-round;
      padding: 0.5rem 1rem;

      &.active {
        background: $yellow;
      }
    }
  }

  .placementModal {
    img {
      width: 100%;
    }
  }

  .placementContainer {
    display: flex;
    justify-content: center;
    margin: 4rem 0;

    > a {
      border-bottom: unset;
      cursor: pointer;
    }

    img {
      height: 20rem;
      width: auto;
    }
  }

}
</style>
